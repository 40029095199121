<template>
    <gjs-editor :pageId="pageId" :Model="Model">
    </gjs-editor>
</template>

<script>
import GjsEditor from "@/components/GjsEditor";
import Page from "@/models/Page";

export default {
    name: "pages-show",
    components: {GjsEditor},
    data: function () {
        return {
            Model: Page,
            pageId: null,
        }
    },
    async mounted() {
        this.pageId = this.$route.params.id
    },
}
</script>
